import React, { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import { Container, Box, LinearProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import Login from "../components/Login"
import LogoDelta from '../assets/logoDelta.png'
import LogoIntegrador from '../assets/integrador.png'
import LogoTecnico from '../assets/tecnico.png'
import LogoGuardiaoVertical from '../assets/logo-guardiao-2-v-v2.png'
import IconeGuardiao from '../assets/images/logoTray.png';

const useStyles = makeStyles((theme) => {
    return {
        container: {
            backgroundColor: '#212121',
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                marginTop: '5rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"item1 item1 item2 item2" "item1 item1 item3 item3"`,
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '0rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 3,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"item2 item2 item2 item2" "item1 item1 item1 item1" "item3 item3 item3 item3"`,
            }
        },
        pageHome: {
            [theme.breakpoints.up('sm')]: {

            },
            [theme.breakpoints.down('sm')]: {
                item3:{
                    visibility: "false"
                }
            }
        },
        pageHomeLeft: {
            [theme.breakpoints.up('sm')]: {

            },
            [theme.breakpoints.down('sm')]: {

            }
        },
        logoDeltaHome: {
            //justifyItems: "center"
            objectFit: "contain",
            objectPosition: "center"
        },
        imgDiv: {
            display: "flex",
            justifyContent: "center"
        },
        item1: {
            gridArea: "item1",
            margin: "auto"
        },
        item2: {
            gridArea: "item2",
            margin: "auto",
            width: "auto",
            paddingTop: "80px",
            marginBottom: "40px",
            objectFit: "contain",
            objectPosition: "center"
        },
        item3: {
            gridArea: "item3",
            margin: "auto",
            width: "auto",
            paddingTop: "40px"
        }
    }
})

export default function HomePage(props) {
    let settings = null;

    const [isLoading, setIsLoading] = useState(true);
    const [isRecovery, setIsRecovery] = useState(true);
    const history = useHistory();
    const classes = useStyles();

    const SystemRecovery = async () => {
        setIsLoading(true);
        setIsRecovery(true);

        console.log("🚀 ~ SystemRecovery")

        try
        {
            const request = await fetch(`${process.env.REACT_APP_URL}/monitor-api/heartbeat/defibrillator`,{
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                }
            });
                
            if(!request.ok)
            {
                throw Error("Não foi possível verificar se o sistema passou por uma recuperação");
            }

            const response = await request.json();
            
            if (!!response.settings)
            {
                settings = response.settings;
            }

            setIsRecovery(response.status);
        }
        catch(error)
        {
            console.log("🚀 ~ SystemRecovery ~ error:", error)
        }
        finally 
        {
            setIsLoading(false);
            
            if (!!settings)
            {
                await DoLogin();
            }
        }
    }

    const DoLogin = async () => {
        try 
        {
            setIsLoading(true);

            const request = await fetch(`${process.env.REACT_APP_URL}/account/login`, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: settings.userName,
                    password: settings.userPass,
                    encript: true
                })
            });

            if (!request.ok)
            {
                throw Error("Não foi possível realizar o Login");
            }
            //setAmplifiersOffline(false);


            const response = await request.json();

            setIsLoading(false);
            verifyUserUpdate(response.user);

            if (response.message) 
            {
                props.token.setToken(response.message)
            } 
            else 
            {
                localStorage.setItem("user", JSON.stringify(response.user));
                localStorage.setItem("token", response.token);
                
                sessionStorage.setItem("serialPortNumber", settings.userPort);
                
                props.setToken(response.token)
                props.setUserData(response.user)
                props.populateData(response.user, response.token)

                if (response.user.iD_EMPRESA === "00000000-0000-0000-0000-000000000000") 
                {
                    history.push("/modo+operacao")
                }
                else if (response.user.role.revenda === true || response.user.role.delta === true || response.user.role.multiCentral === true) 
                {
                    history.push("/painel")
                } 
                else 
                {
                    history.push("/central")
                }
            }
        }
        catch(error)
        {
            console.log("🚀 ~ DoLogin ~ error:", error)
        }
        finally
        {
            setIsLoading(false);
        }
    }

    useEffect(() => { 
        console.log("STORAGES TESTE (BEFORE)", {
            localStorage: { ...localStorage },
            sessionStorage: { ...sessionStorage },
        });

        sessionStorage.removeItem("avoidSyncModem");
        localStorage.removeItem("lastCheckDate");
        SystemRecovery();
      
    }, []);

    //const setAmplifiersOffline = (modemOnline) => {
    //    const serial = sessionStorage.getItem("serial");

    //    if (serial) {
    //        fetch(`${process.env.REACT_APP_URL}/amplifier/set/amplifiers/offline?serialNumber=${serial}&modemOnline=${modemOnline}`, {
    //            method: "POST",
    //            headers: {
    //                "Content-Type": "application/json"
    //            },
    //        })
    //    }
    //}

    const verifyUserUpdate = (user) => {
        fetch(`${process.env.REACT_APP_URL}/usernotification/create-if-doesnt-exists`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })
            .then(response => response.json())
            .then(data => {
                localStorage.removeItem("lastCheckDate");
            })
            .catch(error => {

            });
    }

    //const verifyIfUserExists = (userObj) => {
    //    const serial = sessionStorage.getItem("serial");
    //    const payload = {
    //        user: userObj,
    //        panelSerial: serial, 
    //    };
    //    fetch(`${process.env.REACT_APP_URL}/semredeac/create-if-doesnt-exists`, {
    //        method: "POST",
    //        headers: {
    //            "Content-Type": "application/json"
    //        },
    //        body: JSON.stringify(payload)
    //    })
    //        .then(response => response.json())
    //        .then(data => {
    //            console.log("Success:", data);
    //        })
    //        .catch(error => {
    //            console.error("Error:", error);
    //        });
    //}


    
    // useEffect(() => {
    //     if(!!domReady)
    //     {
    //         setTimeout(() => {
    //         }, 1000)
    //     }
    // }, [domReady]);

    return (
        <Container>
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "0", left: "0", width: "100%", zIndex: "1201"}} /> 
            }
            {!!isRecovery 
            ?
                <div class="container container--centered container--min-height">
                    <img src={IconeGuardiao} class="bx bx-burst"/>
                </div>
            :
                <Box className={classes.container}>
                    <Box className={classes.item1}>
                        <Login darkMode={props.darkMode} data={props} setIsLoading={setIsLoading} />
                    </Box>
                    <Box className={classes.item2}>
                        <img width={350} height={150} src={props.theme === '#f44336' ? LogoIntegrador : props.theme === '#ff9800' ? LogoTecnico : LogoGuardiaoVertical} alt={"Logo Guardião"} className={classes.logoDeltaHome} />
                    </Box>
                    <Box className={classes.item3}>
                        <img width={120} height={50} src={LogoDelta} alt={"Delta Fire"} className={classes.logoDeltaHome} />
                    </Box>
                </Box>
            }
        </Container>
    )
}