class Session {
    constructor(id, companyId, intranetId, master, name, email, password, cnpj, serial, roles, config) {
        this.id = id || "";
        this.companyId = companyId || "";
        this.intranetId = intranetId || "";
        this.master = master || false;
        this.name = name || "";
        this.email = email || "";
        this.password = password || "";
        this.cnpj = cnpj || "";
        this.serial = serial || "";
        this.roles = roles || [];
        this.config = config || {};
    }
}

export default Session;