import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from "../providers/SnackbarProvider";
import Group from '../models/Group';
import Modal from '../includes/Modal';
import AlarmCommandEnum from '../enums/AlarmCommandEnum';
import PanelModelEnum from '../enums/PanelModeEnum';
import commandsProvider from '../providers/CommandsProvider'
import groupsProvider from '../providers/GroupsProvider';

const CommandsPage = () => {
    const history = useHistory();
    const modalRef = useRef(null);
    const { openSnackbar } = useSnackbar();
    const [session, setSession] = useState();

    const [modal, setModal] = useState({ style: null, title: null, alarm: null, icon: null, button: null, groups: [""] });
    const [operationMode, setOperationMode] = useState({ label: PanelModelEnum.NAO_INFORMADO, color: "color-light" });
    const [modemStatus, setModemStatus] = useState(true);
    const [groups, setGroups] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState({});
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupsCounter, setGroupsCounter] = useState(0);
    const [expandedAll, setExpandedAll] = useState(false);
    const [canExpand, setCanExpand] = useState(false);

    const handleGroupSelect = (group) => {
        setSelectedGroups((prevSelectedGroups) => { 
            const isSelected = isGroupSelected(group);
    
            if (isSelected) 
            {
                // Se o grupo já está selecionado, removê-lo
                const updatedSelectedGroups = prevSelectedGroups.filter((g) => g.id !== group.id);
                
                // Se nenhum grupo estiver selecionado, adicionar o grupo "Geral"
                if (updatedSelectedGroups.length === 0) 
                {
                    const geralGroup = groups.find(g => parseInt(g.position.slice(12), 10) === 0);
                    return geralGroup ? [geralGroup] : updatedSelectedGroups;
                }
                
                return updatedSelectedGroups;
            } 
            else 
            {
                const filteredGroups = prevSelectedGroups.filter(
                    (g) => parseInt(g.position.slice(12), 10) !== 0
                );
    
                // Se o grupo "Geral" é selecionado
                if (parseInt(group.position.slice(12), 10) === 0) 
                {
                    return [group];
                }
    
                // Adiciona o novo grupo e verifica se atingimos 6 grupos selecionados
                const newSelectedGroups = [...filteredGroups, group];
    
                if (newSelectedGroups.length === (groups.length - 1)) 
                {
                    const geralGroup = groups.find(g => parseInt(g.position.slice(12), 10) === 0);
                    return geralGroup ? [geralGroup] : newSelectedGroups;
                }             
    
                return newSelectedGroups;
            }
        });
    };

    const initialize = async () => {
        let generalGroup = groupsProvider.getGeneralGroup();
        setGroups([generalGroup]);
        
        await getGroups();
        
        const initialState = commandsProvider.getState();
        updateState(initialState);

        handleGroupSelect(generalGroup);
    }

    const getGroups = async() => {
        try 
        {
            const request = await fetch(`${process.env.REACT_APP_URL}/group/all`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}` 
                },
                body: JSON.stringify({
                    "serialNumber": sessionStorage.getItem("serial"),
                })
            });

            if(!request.ok) throw Error("Não foi possível obter a lista de grupos.");
            
            const response = await request.json();
            const groupInstances = response.map(groupData => new Group(groupData));

            groupsProvider.updateGroupsList(groupInstances);

            setGroups((prevGroups) => {
                return [...prevGroups, ...groupInstances];
            });
        }
        catch (error)
        {
            openSnackbar(error.message, "error");
        }
    }

    const updateOperationMode = (panelMode) => {
        let mode = {
            label: null,
            color: null
        };
        switch(panelMode)
        {
            case PanelModelEnum.NAO_INFORMADO:
                mode.label = panelMode;
                mode.color = "color-light";
                break;
            case PanelModelEnum.NORMAL:
                mode.label = panelMode;
                mode.color = "color-success";
                break;
            case PanelModelEnum.PARCIAL:
                mode.label = panelMode;
                mode.color = "color-warning";
                break;
            case PanelModelEnum.SSPAUTO:
                mode.label = panelMode;
                mode.color = "color-info";
                break;
            case PanelModelEnum.SSPMANUAL:
                mode.label = panelMode;
                mode.color = "color-info";
                break;
            default:
                mode.label = "ERRO";
                mode.color = "color-danger";
                break;
        }
        setOperationMode(mode);
    }

    const updateState = (state) => {
        console.log("🚀 ~ updateState ~ state.modemStatus:", state.modemStatus)
        updateOperationMode(state.panelMode);
        setSession(state.session);
        setModemStatus(state.modemStatus);
        setGroups((prevGroups) => {
            // Atualize os grupos com base nos alarmes
            const updatedGroups = prevGroups.map((group) => {
                const alarm = state.groupAlarms.findLast((alarm) => alarm.groupValue === group.position);
                if (alarm) 
                {
                    group.setAlarm(alarm.command); // Atualiza o grupo com o alarme
                } 
                else 
                {
                    group.clearAlarm(); // Limpa o alarme caso não esteja em groupAlarms
                }

                group.setEvents(state.groupEvents.filter(g => g.position === group.position));

                try {
                    if (!!state.currentAlarm.command && !!state.currentAlarm.groupName)
                    {
                        if (state.currentAlarm.command === "DESL" && (state.currentAlarm.groupName === "Geral")) {
                            group.clearAlarm();
                            group.currentAlarm = "DESL";
                        }
                    }
                } catch { }

                return group;
            });

            return [...updatedGroups];
        });
    }

    const isGroupSelected = (group) => { return selectedGroups.some((selectedGroup) => selectedGroup.id === group.id) };

    const openModal = () => { !!modalRef.current && modalRef.current.open(); };

    const closeModal = () => {
        if (modalRef.current) modalRef.current.close();
        setModal({ style: null, title: null, alarm: null, icon: null, button: null, groups: [""] })
    };

    const requestConfirmation = (alarm) => {
        let modalHandler = { style: null, title: null, alarm: null, icon: null, button: null, groups: [""] };

        modalHandler.alarm = alarm;
        modalHandler.groups = selectedGroups.map((group) => {
            if (group.local === "")
            {
                return `Grupo ${group.position.slice(12)}`;
            }
            else
            {
                return group.local;
            }
        });

        switch(alarm)
        {
            case AlarmCommandEnum.BRIGADA:
                modalHandler.title = "Alarme de Brigada";
                modalHandler.icon = "df-alarm-brigade";
                modalHandler.style = "modal--brigade";
                modalHandler.button = "button--alt";
                break;
            case AlarmCommandEnum.ABANDONO:
                modalHandler.title = "Alarme de Abandono";
                modalHandler.icon = "df-alarm-abandon";
                modalHandler.style = "modal--abandon";
                modalHandler.button = "button--danger";
                break;
            case AlarmCommandEnum.SILENCIAR:
                modalHandler.title = "Silenciar Alarme";
                modalHandler.icon = "df-alarm-visual";
                modalHandler.style = "modal--visual";
                modalHandler.button = "button--warning";
                break;
            case AlarmCommandEnum.RESTAURAR:
                modalHandler.title = "Restaurar Sistema";
                modalHandler.icon = "df-alarm-reset";
                modalHandler.style = "modal--reset";
                modalHandler.button = "button--success";
                break;
            default:
                modalHandler.style = null;
                modalHandler.icon = null;
                modalHandler.style = null;
                modalHandler.button = null;
                break;
        }

        setModal(modalHandler);

        openModal();
    }

    const confirmAlarm = async (alarm) => {
        closeModal();
        // Envia o alarme via CommandProvider
        for (const group of selectedGroups) 
        {
            handleGroupSelect(group);
            group.clearAlarm();
            group.setStatus("busy");
            await commandsProvider.sendAlarm(alarm, group);
            group.setStatus(true);
        }
    }

    const toggleGroupExpansion = (index = null) => {
        if (index !== null) 
        {
            // Expansão individual
            setExpandedGroups((prev) => {
                const updatedGroups = {
                    ...prev,
                    [index]: !prev[index],
                };
                
                // Verifica se todos os grupos com eventos estão expandidos
                const allGroupsExpanded = groups.every((group, i) => !group.hasEvents() || updatedGroups[i]);
                setExpandedAll(allGroupsExpanded);
    
                return updatedGroups;
            });
        } 
        else 
        {
            // Expansão geral
            if (!expandedAll) 
            {
                // Expandir todos os grupos com eventos
                const allExpanded = groups.reduce((acc, group, i) => {
                    acc[i] = true;
                    return acc;
                }, {});
                setExpandedGroups(allExpanded);
                setExpandedAll(true);
            } 
            else 
            {
                // Retrair todos os grupos
                setExpandedGroups({});
                setExpandedAll(false);
            }
        }
    };
    
    useEffect(() => {
        if (selectedGroups.some(g => g.position.slice(12) === '0'))
        {
            setGroupsCounter(groups.length);
        }
        else
        {
            setGroupsCounter(selectedGroups.length);
        }
    }, [selectedGroups]);
    
    useEffect(() => { 
        document.querySelector("#control-top-drawer").checked = false; 
        initialize();

        commandsProvider.subscribe(updateState);
        return () => commandsProvider.unsubscribe(updateState);
    }, []);


    useEffect(() => {
        const anyGroupHasEvents = groups.some(group => group.hasEvents());

        if (anyGroupHasEvents) {
            setCanExpand(true)
        }
        else {
            setCanExpand(false)
        }
    }, [groups]);
                            
    return (
        <main>
            <div className="container">
                <div className="grid">
                    <section className="grid__col">
                        <h1 className="text-center">Painel de Emergência</h1>
                    </section>
                </div>
            </div>
            <div className="container container--commands">
                <div className="grid grid--stretch">
                    <section className="grid__col h-100 mr-5" data-grid-col="8">
                        <div className='grid grid--middle'>
                            <div className='grid__col'>
                                <h3 className='mt-0 mb-0'>1) Selecione um local <span class="color-light">({groupsCounter} / {groups.length})</span></h3>
                            </div>
                            <div className='grid__col grid__col'>
                                <div className='buttons-container buttons-container--right'>
                                    {/* <button type='button' className='button button--xs button--no-hover'>
                                        <i className={`button__icon fa fa-2x fa-cogs ${operationMode.color}`}></i>
                                        <span className='button__label'>{operationMode.label}</span>
                                    </button> */}

                                    <button type="button" className='button button--xs' disabled={!canExpand} title={!canExpand ? "Sem eventos" : ""} onClick={() => toggleGroupExpansion()} >
                                        <i className={`button__icon bx bx-xs ${!expandedAll ? "bx-expand-alt" : "bx-collapse-alt"}`}></i>
                                        <span className='button__label'>{!expandedAll ? "Expandir" : "Retrair"}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='list-container'>
                            <div className='list-container__overlayer list-container__overlayer--marquee'></div>
                            <ul className="list list--clickable mb-0">
                                {groups.map((group, index) => (
                                    <li 
                                        key={index} 
                                        className={`list__item ${group.isBusy() ? "list__item--locked" : ""} ${isGroupSelected(group) ? "list__item--selected" : ""} ${group.isAlarmed() ? "list__item--" + group.getAlarmStyle() : ""}`} 
                                        onClick={(e) => {e.stopPropagation();handleGroupSelect(group);}}
                                        onDoubleClick={(e) => {e.stopPropagation();toggleGroupExpansion(index);}}
                                    >
                                        <div className="grid grid--middle">
                                            <div className="grid__col" data-grid-col="3">
                                                {group.hasEvents() &&
                                                    <label 
                                                        htmlFor={`events-group-${index}`}
                                                        className={`button button--xs button--transparent button--alerted ${group.isTriggered() && "button--alarmed"}`}
                                                        onClick={(e) => {toggleGroupExpansion(index);}}>
                                                        <i className='fa fa-exclamation-triangle fa-lg'></i>
                                                    </label>
                                                }
                                            </div>
                                            <div className="grid__col" data-grid-col="6">
                                                <p className="list__item__label">{group.getGroupLocal()}</p>
                                            </div>
                                            <div className="grid__col text-right" data-grid-col="3">
                                                {/* Exibe o alarme atual */}
                                                {group.isBusy() && <i className={`fa fa-lg fa-spinner fa-pulse mr-2`}></i>}
                                                {(group.isAlarmed() && !group.isBusy()) && 
                                                    <div className='d-flex d-flex--row d-flex--end'>
                                                        <span className='text-monospace text-medium'>{group.getAlarmLabel()}</span>
                                                        <span className='mx-2'>|</span>
                                                        <i className={`fa fa-2x ${group.getAlarmIcon()}`}></i>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {group.hasEvents() &&
                                            <>
                                                <input 
                                                    type='checkbox' 
                                                    id={`events-group-${index}`} 
                                                    className="events-kpi-trigger"
                                                    checked={!!expandedGroups[index]}
                                                    onChange={() => toggleGroupExpansion(index)}
                                                    />
                                                <div className="events-kpi-container">
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Disparo Manual</h6>
                                                        <button type="button" className={`button button--xs ${group.getManualTriggeredCount() > 0 && "button--outline-danger"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getManualTriggeredCount()}</span>
                                                            <i class="button__icon fa fa-3x df-alarm-manual"></i>
                                                        </button>
                                                    </div>
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Disparo Fumaça</h6>
                                                        <button type="button" className={`button button--xs ${group.getSmokeTriggeredCount() > 0 && "button--outline-danger"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getSmokeTriggeredCount()}</span>
                                                            <i class="fa fa-3x df-alarm-smoke"></i>
                                                        </button>
                                                    </div>
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Removidos</h6>
                                                        <button type="button" className={`button button--xs ${group.getRemovedCount() > 0 && "button--outline-warning"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getRemovedCount()}</span>
                                                            <i class="fa fa-3x df-alert-remove"></i>
                                                        </button>
                                                    </div>
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Bateria Baixa</h6>
                                                        <button type="button" className={`button button--xs ${group.getLowBatteryCount() > 0 && "button--outline-warning"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getLowBatteryCount()}</span>
                                                            <i class="fa fa-2x fa-battery-quarter"></i>
                                                        </button>
                                                    </div>
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Sem Rede AC</h6>
                                                        <button type="button" className={`button button--xs ${group.getWithoutEnergyCount() > 0 && "button--outline-warning"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getWithoutEnergyCount()}</span>
                                                            <i class="fa fa-3x df-ac-off"></i>
                                                        </button>
                                                    </div>
                                                    <div className='events-kpi-container__item'>
                                                        <h6 className="text-small mt-0 mb-2">Outros Eventos</h6>
                                                        <button type="button" className={`button button--xs ${group.getOtherEventsCount() > 0 && "button--outline-warning"}`} onClick={() => history.push("/eventos")}>
                                                            <span className="button__badge">{group.getOtherEventsCount()}</span>
                                                            <i class="fa fa-2x fa-exclamation-circle"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </section>
                    <section className="grid__col grid__col--column ml-5" data-grid-col="1">
                        <h3 className='mt-2 mb-5'>2) Ação</h3>
                        <div className="buttons-container buttons-container--vertical">
                            <button 
                                type='button' 
                                className="button button--command button--danger my-0" 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false } 
                                onClick={() => requestConfirmation(AlarmCommandEnum.BRIGADA)}>
                                <span className="button__label">Alarme de Brigada</span>
                                <i className="fa fa-5x df-alarm-brigade"></i>
                            </button>
                            <button 
                                type='button' 
                                className="button button--command button--danger my-0" 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false } 
                                onClick={() => requestConfirmation(AlarmCommandEnum.ABANDONO)}>
                                <span className="button__label">Alarme de Abandono</span>
                                <i className="fa fa-5x df-alarm-abandon"></i>
                            </button>
                            <button 
                                type='button' 
                                className="button button--command button--danger my-0" 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false } 
                                onClick={() => requestConfirmation(AlarmCommandEnum.SILENCIAR)}>
                                <span className="button__label">Silenciar Alarme</span>
                                <i className="fa fa-5x df-alarm-visual"></i>
                            </button>
                            <button 
                                type='button' 
                                className="button button--command button--success my-0" 
                                disabled={!modemStatus ? true : false } 
                                onClick={() => requestConfirmation(AlarmCommandEnum.RESTAURAR)}>
                                <span className="button__label">Restaurar Sistema</span>
                                <i className="fa fa-5x df-alarm-reset"></i>
                            </button>
                        </div>
                    </section>
                </div>
            </div>
            <Modal ref={modalRef} modalId="modal-command-guardiao" className={'modal--command '+ modal.style}>
                <Modal.Header>
                    <span>
                    {modal.groups.length <= 3
                        ? modal.groups.join(", ")
                        : `${modal.groups.slice(0, 3).join(", ")} (+${modal.groups.length - 3})`
                    }
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <h1 className='modal__title'>{modal.title}</h1>
                    <i className={'fa fa-5x ' + modal.icon}></i>
                </Modal.Body>
                <Modal.Footer>
                    <div className='buttons-container buttons-container--stretch px-2 pb-2'>
                        <button type='button' className='button' onClick={() => closeModal()}>
                            <span className='button__label'>Cancelar</span>
                        </button>
                        <button type='button' className={'button ' + modal.button} onClick={() => confirmAlarm(modal.alarm)}>
                            <span className='button__label'>Confirmar</span>
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </main>
    );
}

export default CommandsPage;