import React, { useState, useEffect, useRef } from "react"
import Modal from '../includes/Modal';
import EventCommandEnum from "../enums/EventCommandEnum";
import commandsProvider from "../providers/CommandsProvider";
import groupsProvider from "../providers/GroupsProvider";
import AlarmCommandEnum from "../enums/AlarmCommandEnum";
import AlarmAudio from '../assets/Alarm.mp3'
import BeepAudio from '../assets/Beep.mp3'

export default function EventCard(props) {
    const modalRef = useRef(null);
    const [session, setSession] = useState();

    const [modal, setModal] = useState({ style: null, title: null, alarm: null, icon: null, button: null, groups: [""] });

    const [group, setGroup] = useState(groupsProvider.getGroupByPosition(props.row.position));
    const [modemStatus, setModemStatus] = useState(true);

    const [currentAlarm, setCurrentAlarm] = useState();

    const [isBusy, setIsBusy] = useState();

    const [loadingStates, setLoadingStates] = useState({
        LIGA: false,
        PANI: false,
        DESL: false,
        SILE: false,
    });

    const CardIcon = () => {
        switch (props.row.command) {
            case EventCommandEnum.TEMPERATURA_BAIXA: return "df df-xxl df-thermo-low"; 
            case EventCommandEnum.TEMPERATURA_ALTA: return "df df-xxl df-thermo-high"; 
            case EventCommandEnum.SISTEMA_RESTAURADO: return "df df-xxl df-alarm-reset"; 
            case EventCommandEnum.SINALIZACAO_VISUAL_ATIVADA: return "df df-xxl df-alarm-visual"; 
            case EventCommandEnum.SEM_REDE_AC: return "df df-xxl df-ac-off"; 
            case EventCommandEnum.REMOVIDO: return "df df-xxl df-alert-remove"; 
            case EventCommandEnum.REDE_AC_INADEQUADA: return "df df-xxl df-ac-error"; 
            case EventCommandEnum.MONITOR_DE_PRESSAO: return "df df-xxl df-monitor-pression"; 
            case EventCommandEnum.MONITOR_DE_FLUXO: return "df df-xxl df-monitor-flow"; 
            case EventCommandEnum.MONITOR_DE_ENERGIA: return "df df-xxl df-monitor-energy"; 
            case EventCommandEnum.MONITOR_DE_ACESSO: return "df df-xxl df-monitor-access"; 
            case EventCommandEnum.LINEAR_COM_FALHA: return "df df-xxl df-linear-error"; 
            case EventCommandEnum.FORA_DE_OPERACAO: return "df df-xxl df-operation-off";
            case EventCommandEnum.FALHA: return "df df-xxl df-error"; 
            case EventCommandEnum.EQUIPAMENTO_DESATIVADO: return "df df-xxl df-equipament-off"; 
            case EventCommandEnum.EQUIPAMENTO_ATIVADO: return "df df-xxl df-equipament-on"; 
            case EventCommandEnum.EM_OPERACAO: return "df df-xxl df-work-on"; 
            case EventCommandEnum.DISPARO_POR_TEMPERATURA: return "df df-xxl df-alarm-thermo"; 
            case EventCommandEnum.DISPARO_POR_GAS: return "df df-xxl df-alarm-gas"; 
            case EventCommandEnum.DISPARO_POR_FUMACA: return "df df-xxl df-alarm-smoke"; 
            case EventCommandEnum.DISPARO_POR_CHAMA: return "df df-xxl df-alarm-fire"; 
            case EventCommandEnum.DISPARO_MANUAL: return "df df-xxl df-alarm-manual"; 
            case EventCommandEnum.CONTROLE_DE_TEMPERATURA: return "df df-xxl df-thermo-control"; 
            case EventCommandEnum.CONTROLE_AMBIENTAL: return "df df-xxl df-control-ambiental"; 
            case EventCommandEnum.BATERIA_BAIXA: return "df df-xxl df-battery-low"; 
            case EventCommandEnum.AVISO_DE_BRIGADA: return "df df-xxl df-alert-brigade"; 
            case EventCommandEnum.AMONIA: return "df df-xxl df-amonia"; 
            case EventCommandEnum.ALERTA_MOVEL: return "df df-xxl df-alert-mobile"; 
            case EventCommandEnum.ALARME_VISUAL: return "df df-xxl df-alarm-visual"; 
            case EventCommandEnum.ALARME_EXTRA: return "df df-xxl df-alarm-extra"; 
            case EventCommandEnum.ALARME_DE_RETORNO: return "df df-xxl df-alarm-return"; 
            case EventCommandEnum.ALARME_DE_BRIGADA: return "df df-xxl df-alarm-brigade";
            case EventCommandEnum.ALARME_DE_ABANDONO_LIGADO: return "df df-xxl df-alarm-abandon-on"; 
            case EventCommandEnum.ALARME_DE_ABANDONO: return "df df-xxl df-alarm-abandon"; 
            case EventCommandEnum.ACIONADOR_DE_HIDRANTE: return "df df-xxl df-hydrant"; 
            case EventCommandEnum.ACIONADO: return "df df-xxl df-alarm-manual"; 
            default: return "df df-xxl df-alert-remove"; 
        }
    }

    const bgColor = () => {
        if (props.row.equipamentName === "Sub Central") {
            if (props.row.command === EventCommandEnum.ALARME_DE_RETORNO ||
                props.row.command === EventCommandEnum.ALARME_EXTRA ||
                props.row.command === EventCommandEnum.ALARME_VISUAL) {
                return "bg-color-card-yellow"
            }
            if (props.row.command === EventCommandEnum.SISTEMA_RESTAURADO) {
                return "bg-color-card-green";
            }
            if (props.row.command === EventCommandEnum.ALARME_DE_BRIGADA) {
                return "bg-color-card-orange";
            }
            if (props.row.command === EventCommandEnum.ALARME_DE_ABANDONO) {
                return "bg-color-card-red";
            }
        }
        if (props.row.command === EventCommandEnum.BATERIA_BAIXA ||
            props.row.command === EventCommandEnum.SINALIZACAO_VISUAL_ATIVADA ||
            props.row.command === EventCommandEnum.REMOVIDO ||
            props.row.command === EventCommandEnum.SILENCIAR_ALARME ||
            props.row.command === EventCommandEnum.CONTROLE_AMBIENTAL ||
            props.row.command === EventCommandEnum.SEM_REDE_AC
        ) {
            return "bg-color-card-yellow"
        } else {
            return "bg-color-card-red"
        }
    }

    const ForeColor = () => {
        if (props.row.equipamentName === "Sub Central") {
            if (props.row.command === EventCommandEnum.ALARME_DE_RETORNO ||
                props.row.command === EventCommandEnum.ALARME_EXTRA ||
                props.row.command === EventCommandEnum.ALARME_VISUAL) {
                return "color-card-yellow"
            }
            if (props.row.command === EventCommandEnum.SISTEMA_RESTAURADO) {
                return "color-card-green";
            }
            if (props.row.command === EventCommandEnum.ALARME_DE_BRIGADA) {
                return "color-card-orange";
            }
            if (props.row.command === EventCommandEnum.ALARME_DE_ABANDONO) {
                return "color-card-red";
            }
        }
        if (props.row.command === EventCommandEnum.BATERIA_BAIXA ||
            props.row.command === EventCommandEnum.SINALIZACAO_VISUAL_ATIVADA ||
            props.row.command === EventCommandEnum.REMOVIDO ||
            props.row.command === EventCommandEnum.SILENCIAR_ALARME ||
            props.row.command === EventCommandEnum.CONTROLE_AMBIENTAL ||
            props.row.command === EventCommandEnum.SEM_REDE_AC
        ) {
            //return "#FFEA00"
            return "color-card-yellow"
        } else {
            return "color-card-red"
        }
    }

    const updateCardState = (state) => {
        setModemStatus(state.modemStatus);
        setSession(state.session);
        //updateCurrentAlarm(state.currentAlarm);

        if (!!state.currentAlarm)
        {
            if (state.currentAlarm.groupValue === group.position)
            {
                console.log("🚀 ~ updateCardState ~ state.currentAlarm.command:", state.currentAlarm.command)
            }            

            //Tenho que ver se o grupo está alarmado ainda [groupAlarms], botão do alarme fica animado
            const alarm = state.groupAlarms.findLast((alarm) => alarm.groupValue === group.position);

            console.log("EventCard: ", alarm);

            if (alarm) 
            {
                group.setAlarm(alarm.command); // Atualiza o grupo com o alarme
            } 
            else 
            {
                group.clearAlarm(); // Limpa o alarme caso não esteja em groupAlarms
            }

            if (state.currentAlarm.command === "DESL" && (state.currentAlarm.groupName === "Geral")) {
                group.clearAlarm();
                group.currentAlarm = "DESL";
            }
        }
    }

    const openModal = () => { !!modalRef.current && modalRef.current.open(); };

    const closeModal = () => {
        if (modalRef.current) modalRef.current.close();
        setModal({ style: null, title: null, alarm: null, icon: null, button: null, groups: [""] })
    };

    const requestConfirmation = (alarm) => {
        let modalHandler = { style: null, title: null, alarm: null, icon: null, button: null, groups: [""] };
        let groups = group;//groupsProvider.getGeneralGroup();

        modalHandler.alarm = alarm;
        modalHandler.groups = [group.local];

        switch (alarm) {
            case AlarmCommandEnum.BRIGADA:
                modalHandler.title = "Alarme de Brigada";
                modalHandler.icon = "df-alarm-brigade";
                modalHandler.style = "modal--brigade";
                modalHandler.button = "button--alt";
                break;
            case AlarmCommandEnum.ABANDONO:
                modalHandler.title = "Alarme de Abandono";
                modalHandler.icon = "df-alarm-abandon";
                modalHandler.style = "modal--abandon";
                modalHandler.button = "button--danger";
                break;
            case AlarmCommandEnum.SILENCIAR:
                modalHandler.title = "Silenciar Alarme";
                modalHandler.icon = "df-alarm-visual";
                modalHandler.style = "modal--visual";
                modalHandler.button = "button--warning";
                break;
            case AlarmCommandEnum.RESTAURAR:
                modalHandler.title = "Restaurar Sistema";
                modalHandler.icon = "df-alarm-reset";
                modalHandler.style = "modal--reset";
                modalHandler.button = "button--success";
                break;
            default:
                modalHandler.style = null;
                modalHandler.icon = null;
                modalHandler.style = null;
                modalHandler.button = null;
                break;
        }

        setModal(modalHandler);
        openModal();
    }

    const confirmAlarm = async (alarm) => {
        closeModal();
        try {
            setIsBusy(alarm);
            //setLoadingStates((prev) => ({ ...prev, [alarm]: true }));
            let groups = group;//groupsProvider.getGeneralGroup();
            await commandsProvider.sendAlarm(alarm, groups);
        }
        catch (error) {
            console.error("🚀 ~ confirmAlarm ~ error:", error);
        }
        finally
        {
            setIsBusy("");
        }
    }

    useEffect(() => {  
        commandsProvider.subscribe(updateCardState);
        return () => commandsProvider.unsubscribe(updateCardState);
    }, []);

    /*const updateCurrentAlarm = (currentAlarm) => {
        if (!!currentAlarm) {
            if (currentAlarm.groupValue.slice(12) === "0") {
                if (currentAlarm.command === AlarmCommandEnum.RESTAURAR) {
                    setCurrentAlarm(currentAlarm.command);

                    setTimeout(() => { setCurrentAlarm("") }, 3000)
                }
                else {
                    setCurrentAlarm(currentAlarm.command);
                }
            }
        }
        else {
            setCurrentAlarm("");
        }
    }*/

    const handleClick = async (command) => {
        try 
        {
            setLoadingStates((prev) => ({ ...prev, [command]: true }));
            await commandsProvider.sendAlarm(command, group);
        }
        catch (error)
        {
            console.error("Error in sendCommand:", error);
        }
        finally
        {
            setLoadingStates((prev) => ({ ...prev, [command]: false }));
        }
    };

    return (
        <div className="info-box info-card info-box--full h-100 mx-auto">
            <div className={`info-box__header info-card__header ${bgColor()}`} >
                <span><strong>GRUPO {group.position.slice(12)}{ !!group.local && ` - ${group.local}`}</strong></span>
            </div>
            <div class="info-box__body px-3">
                <h1 class="info-card__title mt-2 mb-1 text-center">
                    {props.row.command}
                </h1>
                <i class={`${CardIcon()} ${ForeColor()}`}></i>
                <small>
                    {props.row.equipamentId}
                </small>
                <h3 class="mt-1 mb-1">
                    {props.row.equipamentName}
                </h3>
                <h3 class="mt-1 mb-4">
                    {props.row.local}
                </h3>

                    {/*? ((props.userRole.alarmes === true) &&*/}
                {process.env.REACT_APP_MODE === 'desktop' ?
                        (props.row.command === EventCommandEnum.BATERIA_BAIXA ||
                        props.row.command === EventCommandEnum.SINALIZACAO_VISUAL_ATIVADA ||
                        props.row.command === EventCommandEnum.REMOVIDO ||
                        props.row.command === EventCommandEnum.CONTROLE_AMBIENTAL ||
                        props.row.command === EventCommandEnum.SEM_REDE_AC ||
                        props.row.equipamentName === "Sub Central")
                        ?
                        null
                        :
                        <div class="button-grid">

                            <button 
                                type="button"
                                className={`button button--danger ${isBusy === AlarmCommandEnum.BRIGADA && "button--loading"} ${group.currentAlarm === AlarmCommandEnum.BRIGADA && "command--triggered"}`} 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false}
                                onClick={() => requestConfirmation(AlarmCommandEnum.BRIGADA)}>
                                <i className={`${isBusy === AlarmCommandEnum.BRIGADA
                                    ?
                                    "fa fa-spinner fa-spin fa-2x button__loading ml-1 mr-2"
                                    :
                                    "fa fa-3x df-alarm-brigade button__icon"}`}></i>
                                <span class="button__label ml-0">Alarme de Brigada</span>
                            </button>

                            <button 
                                type="button"
                                className={`button button--danger ${isBusy === AlarmCommandEnum.ABANDONO && "button--loading"} ${group.currentAlarm === AlarmCommandEnum.ABANDONO && "command--triggered"}`} 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false}
                                onClick={() => requestConfirmation(AlarmCommandEnum.ABANDONO)}>
                                <i className={`${isBusy === AlarmCommandEnum.ABANDONO
                                    ?
                                    "fa fa-spinner fa-spin fa-2x button__loading ml-1 mr-2"
                                    :
                                    "fa fa-3x df-alarm-abandon button__icon"}`}></i>
                                <span class="button__label ml-0">Alarme de Abandono</span>
                            </button>

                            <button 
                                type="button"
                                className={`button button--danger ${isBusy === AlarmCommandEnum.SILENCIAR && "button--loading"} ${group.currentAlarm === AlarmCommandEnum.SILENCIAR && "command--triggered"}`} 
                                disabled={!modemStatus || session?.roles?.tecnico ? true : false}
                                onClick={() => requestConfirmation(AlarmCommandEnum.SILENCIAR)}>
                                <i className={`${isBusy === AlarmCommandEnum.SILENCIAR
                                    ?
                                    "fa fa-spinner fa-spin fa-2x button__loading ml-1 mr-2"
                                    :
                                    "fa fa-3x df-alarm-visual button__icon"}`}></i>
                                <span class="button__label ml-0">Silenciar Alarme</span>
                            </button>

                            <button 
                                type="button"
                                className={`button button--success ${isBusy === AlarmCommandEnum.RESTAURAR && "button--loading"}`} 
                                disabled={!modemStatus ? true : false}
                                onClick={() => requestConfirmation(AlarmCommandEnum.RESTAURAR)}>
                                <i className={`${isBusy === AlarmCommandEnum.RESTAURAR
                                    ?
                                    "fa fa-spinner fa-spin fa-2x button__loading ml-1 mr-2"
                                    :
                                    "fa fa-3x df-alarm-reset button__icon"}`}></i>
                                <span class="button__label ml-0">Restaurar Sistema</span>
                            </button>

                            <Modal ref={modalRef} modalId="modal-rapid-command" className={'modal--command ' + modal.style}>
                                <Modal.Header>
                                    <span>
                                        {modal.groups.length <= 3
                                            ? modal.groups.join(", ")
                                            : `${modal.groups.slice(0, 3).join(", ")} (+${modal.groups.length - 3})`
                                        }
                                    </span>
                                </Modal.Header>
                                <Modal.Body>
                                    <h1 className='modal__title'>{modal.title}</h1>
                                    <i className={'fa fa-5x ' + modal.icon}></i>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='buttons-container buttons-container--stretch px-2 pb-2'>
                                        <button type='button' className='button' onClick={() => closeModal()}>
                                            <span className='button__label'>Cancelar</span>
                                        </button>
                                        <button type='button' className={'button ' + modal.button} onClick={() => confirmAlarm(modal.alarm)}>
                                            <span className='button__label'>Confirmar</span>
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    : null
                }
            </div>
            <div class="info-box__footer info-card__footer" >
                <div textAlign="center">
                    <label>
                        {new Date(props.row.datetime).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",","")}
                    </label>
                </div>
            </div>
        </div>
    )
}