import {useState, useEffect} from 'react'
import { Card, Box, CardHeader, FormControlLabel, Checkbox } from "@mui/material"
import { BatteryAlert } from '@mui/icons-material'
import makeStyles from "@mui/styles/makeStyles"
import commandsProvider from '../providers/CommandsProvider'
import { useSnackbar } from "../providers/SnackbarProvider";

const useStyles = makeStyles({
    pageCentral: {
        minHeight: "100vh",
    },
    cardCentral: {
        padding: 0,
        width: '100%', //50vw
        margin: 'auto',
        marginTop: '10px',
        marginBottom: 20
    },
})

export default function LowBatteryChecker() 
{
    const classes = useStyles();
    const { openSnackbar } = useSnackbar();

    const [showLowBattery, setShowLowBattery] = useState(false);

    const verifyUserShowLowBattery = () => {
        setShowLowBattery(commandsProvider.getShowLowBattery());
    }

    const checkedOptionShowLowBattery = () => {
        const newValue = !showLowBattery;
        setShowLowBattery(newValue);
        updateSessionState(newValue);
    };

    const updateSessionState = async (value) => {
        commandsProvider.setShowLowBattery(value);
        
        try
        {
            const request = await fetch(`${process.env.REACT_APP_URL}/generalconfigs/createOrUpdate?panelSerial=${sessionStorage.getItem('serial')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    PanelSerial: sessionStorage.getItem('serial'),
                    LastChanged: new Date().toISOString().split('.')[0],
                    ShowLowBattery: value
                })
            });

            if (!request.ok)
            {
                throw new Error('Erro ao atualizar o estado de exibição de bateria baixa');
            }
            
            openSnackbar(!!value ? "Eventos de bateria baixa habilitados" : "Eventos de bateria baixa desabilitados", "success");
        }
        catch (error)
        {
            console.log(error);
            openSnackbar(error.message, "error");
        }
    }

    useEffect(() => {
        verifyUserShowLowBattery();
    }, []);

    return (
        <Card className={classes.cardCentral}>
            <CardHeader
                avatar={<BatteryAlert color="primary" />}
                title={"Status Bateria Baixa"}
            />
            <Box marginTop={"10px"} marginBottom={"10px"} marginLeft={"50px"}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showLowBattery}
                            onChange={checkedOptionShowLowBattery}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Mostrar estado de bateria baixa dos equipamentos do sistema"
                    disabled={process.env.REACT_APP_MODE === "web" ? true : false}
                />
            </Box>
        </Card>
    )
}